<template>
  <div :class="page">
    <h1>{{ page }}</h1>
    <div class="gallery-wrapper d-flex flex-row flex-wrap h-50">
        <LightBox ref="lightbox" :imageList="imageList"/>
        <Thumbnail v-for="link in imageList"  v-bind:key="link.src" :imgsrc="link.src" :alttxt="link.alt" :position="link.position" @fullImage='(imgsrc, alttxt) => something(imgsrc, alttxt)' />
    </div>
  </div>
</template>
<script>

// @ is an alias to /src
import Thumbnail from '@/components/Thumbnail.vue'
import LightBox from '@/components/LightBox.vue'
//import filelist from '@/assets/filelist.json'
//later try to import file list from json instead of router

export default {
  name: 'Home',
  props: {
      page: String,
      imageList: Array,
  },
  data() {
      return{
          imgsrc: '',
          alttxt: '', 
      };
  },
  components: {
    Thumbnail,
    LightBox
  }, 
  methods: {
      something( one, two){
          this.imgsrc = one;
          this.alttxt = two;
          console.log(this.imgsrc);
          console.log(this.alttxt);
          this.$refs.lightbox.fullImage(this.imgsrc, this.alttxt);
      },
      closeCarousel(){
          console.log('close carousel');

      },
      navCarousel(){
          console.log('nav carousel');
      }
  }, 
}
</script>
<style>
.veil{
    background:#000000e0;
    position:fixed;
    width:100%;
    height:100%;
    top:0px;
    left:0px;
    text-align:center;
    overflow:hidden;
}
.fullscreen{
    width:auto;
    margin:0px auto;
    height:95vh;
    width:100vw;
    padding:1em;
    moz-object-fit:contain;
    object-fit:contain;

}
</style>
