<template>
  <div ref="veil" :class="'veil '+ifCarousel" @click.self="closeCarousel" @keyup.esc = "closeCarousel" @keyup.right="navCarousel(1)"  @keyup.left="navCarousel(-1)"  tabindex="0">  
      <img v-if="hasImage" class="fullscreen" :src="require(`@/assets/${imageList[index].src}`)" @click.self="closeCarousel" :alt="imageList[index].alt" />
      <div v-if="hasImage" id="exit-carousel" @click.self="closeCarousel" >x</div>
      <h4 v-if="hasImage" >{{imageList[index].alt}}</h4>

      <div id="prev-carousel" @click="navCarousel(-1)"><div>&lt;</div></div>
      <div id="next-carousel" @click="navCarousel(1)"><div>&gt;</div></div>
  </div>
</template>


<script>
export default {
  name: 'LightBox',
  props: {
      imageList: Array,
  },
  data() {
      return{
        carousel: false,
        //alttxt: 'alt text', 
        //imgsrc: this.imageList[0].src, 
        index: 0, 
        canFullScreen: true,
      }
  },
  methods: {
      openCarousel() {
          this.carousel = true;
      },
      closeCarousel() {
          this.carousel = false;
      },
      getIndex( src, alt) {
          console.log("find", src, alt);
          return this.imageList.findIndex((img) => {
                  return img.src === src && img.alt === alt;
              });
      },
      navCarousel(direction) {
          console.log(this.index, direction);
          var newInd =  this.index + direction;
          if (newInd < 0 ){
              newInd = this.imageList.length-1;
              console.log('loop!')
          } else if ( newInd > this.imageList.length-1){
              newInd = 0; 
              console.log('loop?!')
          }
          
          this.fullImage(this.imageList[newInd].src , this.imageList[newInd].alt);
      },
      sayHi() {
          console.log('lightbox hi');
      },
      hasImage(){
          console.log('does it');
          return this.imgsrc.length > 0; 

      },
      fullImage(imgsrc, alttxt){
          //this.imgsrc = imgsrc;
          //this.alttxt = alttxt; 
          this.index = this.getIndex(imgsrc, alttxt);
          if (this.index <  0) this.index = 0;
          console.log('full image ', imgsrc);
          this.openCarousel();
      }
  },
    /*j
  mounted() {
      this.$root.$on('fullImage', (imgsrc, alttxt) =>{
          this.imgsrc = imgsrc;
          this.alttxt = alttxt; 
          this.openCarousel();
      });
  },*/
  computed: {
        ifCarousel: function() {
            return this.carousel ? "" : "d-none"
        }
        
    },
  updated() {
        if (this.carousel) {
            this.$refs.veil.focus()
        }
        
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.thumbnail{
    display:inline-block;
    overflow:hidden;
    margin:3.25vw;
    margin-bottom:1%;
    margin-top:5vw;
    
    

}
img{
    width:25vw;
    height:25vw;
    min-width:100px;
    min-height:100px;
    object-fit:cover;
}
.thumbpos-left{
    object-position:0;
}
.thumbpos-right{
    object-position:100%;
}
.thumbpos-bottom{
    object-position:50% 100%;
}
.thumbpos-top{
    object-position:50% 0%;
}
.veil{
    background:#000000e0;
    position:fixed;
    width:100%;
    height:100%;
    top:0px;
    left:0px;
    text-align:center;
    overflow:hidden;
}
.fullscreen{
    width:auto;
    margin:0px auto;
    height:95vh;
    width:100vw;
    padding:1em;
    moz-object-fit:contain;
    object-fit:contain;

}
h4{
    margin-bottom:0px;
}
#exit-carousel, #next-carousel, #prev-carousel{
    position: fixed;
    color:grey;
    font-size:2em;
    cursor:pointer;
    padding:.5rem;
    
}
#exit-carousel{
    top:0em;
    right:1.5rem;
    font-size:1.5em;
    padding:0;
    font-weight:600;
}
#next-carousel, #prev-carousel{
    top:20vh;
    height:60vh;
    width:40vw;
    display:flex;
}
#next-carousel{
    right:1.5rem;
}
#prev-carousel{
    left:1.5rem;
}
#next-carousel div, #prev-carousel div{
    display:grid;
    align-content:center;
}
#next-carousel div{
    margin-left: auto;
}




</style>
